<template>
    <div class="page bg-white" id="reorganize">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="lump">
                    <div class="catalogueBox">
                        <div class="flex_l_c">
                            <div class="f_w">展览：</div>
                            <el-input class="flex1" v-model="catalogueSrh" maxlength="66" clearable
                                      placeholder="请输入展览名称"
                                      size="small"></el-input>
                        </div>
                        <el-table :data="catalogueList" ref="catalogueTable" size="small" height="calc(100vh - 268px)"
                                  class="table"
                                  highlight-current-row @current-change="handleCurrentChange">
                            <el-table-column prop="exhibitionName" label="展览" show-overflow-tooltip/>
                        </el-table>
                        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                                       :current-page="pageNo"
                                       :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
                                       layout="prev, pager, next">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="lump">
                    <div class="flex_b_c headBox">
                        <div class="flex_l_c">
                            <el-input class="flex1 m_r1" maxlength="66" v-model="fileSrh" clearable
                                      placeholder="请输入文件名称"
                                      size="small"></el-input>
                            <el-button icon="el-icon-search" type="primary" size="small" @click="searchFileList">查询
                            </el-button>
                            <span class="m_l1 unsatisfy">对检索结果不满意？试试<el-button size="small" type="text"
                                                                               @click="advancedQuery">高级检索</el-button></span>
                        </div>
                        <div>
                            <el-dropdown class="m_r1">
                                <el-button icon="el-icon-printer" size="small" plain>
                                    打印报表
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <!--                                    (item.reportType==0 && dataListSelections.length <= 0) || (item.reportType==1 && (dataListSelections.length==0 || dataListSelections.length>1)) -->
                                    <el-dropdown-item v-for="(item, index) in report" :key="index"
                                                      @click.native="reportPrint(item)"
                                                      :disabled="dataListSelections.length == 0">{{ item.name }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <!-- <el-button icon="el-icon-document" size="small" plain @click="getLog">日志</el-button> -->
                        </div>
                    </div>
                    <div class="catalogueBox">
                        <div class="flex_b_c">
                            <div class="f_w">档案</div>
                            <div>
                                <el-dropdown class="m_l1">
                                    <el-button type="primary" size="small">
                                        智能操作<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="download()"
                                                          :disabled="dataListSelections.length <= 0">下载
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="back()"
                                                          :disabled="dataListSelections.length <= 0">退回
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="permissions()"
                                                          :disabled="dataListSelections.length <= 0">权限调整
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <el-button-group class="m_l1">
                                    <el-button size="small" plain icon="el-icon-refresh"
                                               @click="searchFileList"></el-button>
                                    <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                                </el-button-group>
                            </div>
                        </div>
                        <el-table :data="fileList" size="small" @selection-change="selectionChangeHandle"
                                  v-loading="loading"
                                  height="calc(100vh - 320px)" class="table" ref="tableBox">
                            <el-table-column type="selection" width="50" fixed/>
                            <el-table-column v-for="(item, index) in config" :key="item.id" :prop="item.fieldEnname"
                                             :label="item.recordName" show-overflow-tooltip v-if="item.onShow == 0">
                                <template slot-scope="scope">
                                    <el-tag type="success" v-if="item.fieldEnname == 'fileFormat'">{{
                                        scope.row.fileFormat }}
                                    </el-tag>
                                    <!--                                    <div v-else>{{scope.row[item.fieldEnname]}}</div>-->
                                    <div v-if="item.fieldEnname != 'fileFormat' && scope.row[item.fieldEnname]">
                                        {{ scope.row[item.fieldEnname] }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="250">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain @click="view(scope.row.id)">详情
                                    </el-button>
                                    <el-button size="mini" type="primary" plain @click="download(scope.row)">下载
                                    </el-button>
                                    <el-button size="mini" type="primary" plain @click="print(scope.row)">打印</el-button>
                                    <el-button size="mini" type="primary" plain @click="permissions2(scope.row.id)">
                                        权限调整
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @size-change="sizeChangeHandle2" @current-change="currentChangeHandle2"
                                       :current-page="pageNo2"
                                       :page-sizes="[10, 20, 50, 100]" :page-size="pageSize2" :total="total2" background
                                       layout="total, prev, pager, next, sizes">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
        <!-- 详情 -->
        <FileDetails ref="fileDetails" @refreshDataList="getFileList"></FileDetails>
        <!--    日志    -->
        <Log ref="reorganizeLog"></Log>

        <User ref="user" @refreshDataList="getFileList"></User>
        <!--高级检索-->
        <AdvancedQuery ref="advancedQuery" @getDataList="searchGetDataList"></AdvancedQuery>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop' // 导入排序弹窗
    import FileDetails from './fileDetails' // 详情
    import Log from "../../dataCollection/log"; // 上传日志
    import AdvancedQuery from '../../search/advancedQuery' // 高级查询弹窗
    import User from "../user";
    import {hiprint, defaultElementTypeProvider} from '../../../../../utils/report'

    export default {
        components: {DraggablePop, FileDetails, Log, User, AdvancedQuery},
        data() {
            return {
                // 展览
                catalogueSrh: '',
                catalogueList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                // 文物清单
                fileSrh: '',
                exhibitionId: "",
                fileList: [],
                loading: false,
                pageNo2: 1,
                pageSize2: 10,
                total2: 0,
                moduleId: 2,   // 当前请求拖拽排序数据id
                setShow: false, // 是否显示列表设置拖拽排序弹窗
                dataListSelections: [],
                log: {
                    operModular: "5",//模块
                    operType: "11",//类型
                    operTerm: "",//内容
                    operSystem: "1",//结果
                },
                config: [],
                report: [],
                type: 0,
                dateList: [],
            }
        },
        watch: {
            // 实时输入查询目录
            catalogueSrh() {
                this.searchCatalogue();
            },
        },
        mounted() {
            this.getCatalogue();
            // this.getFileList();
            this.querySysOriginalDescriptionAll();
            this.getReport();
        },
        methods: {
            advancedQuery() {
                this.$refs.advancedQuery.init(this.config)
            },
            searchGetDataList(data) {
                this.pageNo2 = 1
                this.type = 1
                this.dateList = data
                this.getDataList(data);
            },
            //高级查询检索返回
            getDataList(data) {
                this.$axios(this.api.zlzs.seniorQuerySQL, {
                    'pageNo': this.pageNo2,
                    'pageSize': this.pageSize2,
                    'flag': 2,
                    'onDetails': 1,
                    "seniorQueries": data
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.fileList = data.data.records
                        let that = this;
                        this.fileList.forEach(function (v) {
                            that.$set(v, "filePath", v.url);
                            // v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.length)
                        })
                        this.total2 = parseInt(data.data.total)
                        this.loading = false
                        this.$refs.tableBox.doLayout();
                    }
                })
            },
            getReport() {
                this.$axios(this.api.zlzs.templateAll, {}, 'get').then(data => {
                    if (data && data.status) {
                        this.report = data.data
                    }
                })
            },
            //报表打印
            reportPrint(item) {
                let json = {}
                //模板json数据
                json = JSON.parse(item.json)
                //初始化
                hiprint.init({
                    providers: [new defaultElementTypeProvider()]
                });
                //添加json数据
                var hiprintTemplate = new hiprint.PrintTemplate({
                    template: json
                });
                if (item.reportType == 0) {//表格：定义数据字段名
                    let that = this;
                    this.dataListSelections.forEach(function (v) {
                        if (v.writtenDate.lastIndexOf("-") > -1) {
                            v.writtenDate = that.dateFormat(v.writtenDate)
                        }
                    })
                    this.$set(json, "tableDate", this.dataListSelections)
                } else {//文本：循环数据获取字段名
                    for (let key2 of Object.keys(this.dataListSelections[0])) {
                        this.$set(json, key2, this.dataListSelections[0][key2])
                        this.$set(json, "num", this.dataListSelections.length + " 份")
                    }
                }
                hiprintTemplate.print(json, {}, {
                    callback: () => {
                    }
                })
            },
            //格式化日期
            dateFormat(time) {
                var date = new Date(time)
                var year = date.getFullYear()
                /* 在日期格式中，月份是从0开始的，因此要加0
                * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                * */
                var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
                var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
                var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
                var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
                var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
                // 拼接
                return year + month + day
            },
            querySysOriginalDescriptionAll() {
                let that = this;
                this.$axios(this.api.zlzs.querySysOriginalDescriptionAll, {
                    flag: 2,
                    onDetails: 1
                }, 'get').then(data => {
                    if (data) {
                        this.config = data
                        this.config.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'createId') {
                                v.fieldEnname = 'createName'
                            }
                            if (v.fieldEnname == 'quanzongNo') {
                                v.fieldEnname = 'exhibitionName'
                            }
                            if (v.fieldEnname == 'uploadTarget') {
                                v.fieldEnname = 'directoryName'
                            }
                            // that.$set(that.inputForm,v.fieldEnname,"")
                        })
                    }
                })
            },
            toHump(name) {
                return name.replace(/\_(\w)/g, function (all, letter) {
                    return letter.toUpperCase();
                });
            },
            searchCatalogue() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.getCatalogue();
                this.fileList = [];
            },
            // 查询展览
            getCatalogue() {
                this.$axios(this.api.zlzs.queryExhibition, {
                    'name': this.catalogueSrh,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.catalogueList = data.data.records
                        this.total = parseInt(data.data.total)
                    }
                })
            },
            // 点击展览行
            handleCurrentChange(val) {
                this.$refs.catalogueTable.setCurrentRow(val);
                this.exhibitionId = val.id
                this.pageNo2 = 1;
                this.getFileList();
            },
            searchFileList() {
                this.type = 0
                this.pageNo2 = 1;
                this.getFileList();
            },
            // 查询文物清单
            getFileList() {
                if (this.exhibitionId) {
                    this.loading = true
                    this.$axios(this.api.zlzs.queryAllByLimitManagement, {
                        'name': this.fileSrh,
                        "exhibitionId": this.exhibitionId,
                        'pageNo': this.pageNo2,
                        'pageSize': this.pageSize2,
                        'flag': 2,
                        'onDetails': 1,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            let that = this;
                            this.fileList = data.data.records
                            this.fileList.forEach(function (v) {
                                that.$set(v, "filePath", v.url);
                                // v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.url.length)
                            })
                            this.total2 = parseInt(data.data.total)
                            this.loading = false
                            this.$refs.tableBox.doLayout();
                        }
                    })
                }

            },
            // 文物清单表格多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 详情
            view(id) {
                this.$refs.fileDetails.init(id)
            },
            addWatermark(url, fileFormat) {
                let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
                let sourceFile = JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
                return new Promise((resolve, reject) => {
                    if (fileFormat == "pdf" || fileFormat == "PDF") {
                        this.$axios("zyd-catalogue/pdf/addWatermark", {
                            "target": url,
                            "sourceFile": sourceFile,
                            "watermark": watermark
                        }, 'post').then(data => {
                            if (data.status) {
                                resolve(data.data)
                            } else {
                                resolve("")
                            }
                        })
                    } else {
                        resolve("")
                    }
                })
            },
            // 下载
            download(row) {
                let rows = [];
                if (row) {
                    rows = [row]
                } else {
                    rows = this.dataListSelections
                }
                let that = this;
                this.log.operType = '19'
                this.log.operSystem = '1'
                rows.forEach(v => {
                    this.$axios(this.api.zlzs.fileCollectQueryById, {
                        'id': v.id,
                        'flag': 0,
                        'onDetails': 0
                    }, 'get').then(data => {
                        if (data.status) {
                            that.log.operTerm = v.fileName
                            that.addWatermark(v.filePath, v.fileFormat).then(data => {
                                let url = ""
                                if (data) {
                                    url = data
                                } else {
                                    url = v.url;  // 完整的url则直接使用
                                }
                                const a = document.createElement('a')
                                fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                                    a.href = URL.createObjectURL(blob)
                                    a.download = v.fileName + "." + v.fileFormat // 下载文件的名字
                                    document.body.appendChild(a)
                                    a.click()
                                    that.log.operSystem = '0'
                                })
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            //退回
            back() {
                this.$confirm(`确定退回所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.log.operType = "11"
                    let ids = this.dataListSelections.map(item => {
                        return item.id
                    }).join(',')
                    this.loading = true
                    this.$axios(this.api.zlzs.back, {
                        'ids': ids,
                    }, 'put').then(data => {
                        if (data && data.status) {
                            this.log.operSystem = '0'
                            this.$message.success("已退回")
                            // this.getFileList();
                        } else {
                            this.$message.error(data.msg)
                            this.log.operSystem = '1'
                        }
                        this.logSave(this.dataListSelections);
                    })
                })
            },
            permissions() {
                this.$refs.user.init(this.dataListSelections, 0)
            },
            permissions2(id) {
                this.$refs.user.init(0, id)
            },
            //新增日志
            logSave(dataListSelections) {
                let that = this;
                let batchList = []
                dataListSelections.forEach(function (v) {
                    let log = JSON.parse(JSON.stringify(that.log))
                    log.operTerm = v.fileName
                    batchList.push(log)
                })
                // this.$axios(this.api.zlzs.batchInsertLogin, batchList, 'post').then(data => {
                    this.getFileList()
                // })
            },
            //查看日志
            getLog() {
                this.$refs.reorganizeLog.init('5')
            },
            //打印
            print(row) {
                let fileType = row.fileFormat
                if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'bmp' || fileType === 'gif'
                    || fileType === 'PNG' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'BMP' || fileType === 'GIF') {
                    fileType = 'image'
                } else if (fileType === 'pdf' || fileType === 'PDF') {
                    fileType = 'pdf'
                }
                this.$axios(this.api.zlzs.fileCollectQueryById, {
                    'id': row.id,
                    'flag': 0,
                    'onDetails': 0
                }, 'get').then(data => {
                    if (data.status) {
                        this.addWatermark(row.filePath, row.fileFormat).then(data => {
                            let url = ""
                            if (data) {
                                url = data
                            } else {
                                url = row.url;  // 完整的url则直接使用
                            }
                            if (fileType == 'pdf' || fileType == 'image') {
                                this.$print({
                                    printable: url,
                                    type: fileType,
                                    header: null,
                                    targetStyles: ['*'],
                                    style: "@page {margin:0 10mm}"
                                })
                            } else {
                                this.$message.error("仅支持图片和pdf格式文件！")
                            }
                        })
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            // 设置
            setTb() {
                this.setShow = true;
            },
            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.config = data;
                this.getFileList();
                this.setShow = state;
            },
            // 展览每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getCatalogue();
            },
            // 展览当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getCatalogue();
            },
            // 文物清单每页数
            sizeChangeHandle2(val) {
                this.pageSize2 = val;
                this.pageNo2 = 1;
                if (this.type == 0) {
                    this.getFileList();
                } else {
                    this.getDataList(this.dateList)
                }
            },
            // 文物清单当前页
            currentChangeHandle2(val) {
                this.pageNo2 = val;
                if (this.type == 0) {
                    this.getFileList();
                } else {
                    this.getDataList(this.dateList)
                }
            },
        }
    }
</script>

<style scoped></style>
