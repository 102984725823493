import { render, staticRenderFns } from "./FileManagement.vue?vue&type=template&id=aebb31d0&scoped=true&"
import script from "./FileManagement.vue?vue&type=script&lang=js&"
export * from "./FileManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aebb31d0",
  null
  
)

export default component.exports